<template>
  <div class="container-fluid" style="min-height:60vh">
    <div class="row">
      <div class="col-12 p-half">
        <dx-data-grid
          :data-source="departments"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="false"
          :hover-state-enabled="true"
          :customize-columns="customizeColumns"
          @initialized="onInitialized"
          @content-ready="onContentReady"
        >
          <dx-toolbar>
            <dx-item location="before" name="searchPanel" />
            <dx-item location="after" template="buttonTemplate" />
          </dx-toolbar>
          <dx-search-panel :visible="true" :width="200" placeholder="Search..." />
          <dx-column data-field="departmentName" caption="Department" />
          <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="100" :fixed="true" fixed-position="left" />
          <template #buttonTemplate>
            <dx-util-button icon="add" type="success" text="Add Department" @click="openDepartmentForm" />
          </template>
          <template #itemActions="{data}">
            <div>
              <dx-util-button
                type="warning" icon="bi bi-pencil-square"
                class="mr-half" hint="Edit User"
                @click="onClickEdit(data)"
              />
              <dx-util-button
                type="danger" icon="bi bi-trash-fill"
                hint="Delete"
                @click="onClickDelete(data.data.id)"
              />
            </div>
          </template>
        </dx-data-grid>
      </div>
      <div class="col-12">
         <dx-util-popup
          ref="departmentFormPopupRef"
          :show-close-button="true"
          :drag-enabled="false"
          :close-on-outside-click="false"
          :show-title="true"
          :width="400"
          height="auto"
          title="Department Form"
          @hidden="resetForm"
        >
          <dx-util-position at="center" my="center" />
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelButtonOptions" />
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="saveButtonOptions" />
          <form method="post" @submit.prevent="handleSubmit">
            <dx-util-form ref="departmentFormRef" :form-data="departmentForm" :col-count="1" :show-colon-after-label="true" label-location="top" validation-group="departmentGroup">
              <dx-util-group-item :col-count="1">
                <dx-util-item data-field="departmentName" editor-type="dxTextBox" :label="{text: 'Department Name'}">
                  <dx-util-required-rule message="Department Name is required" />
                </dx-util-item>
              </dx-util-group-item>
            </dx-util-form>
          </form>
        </dx-util-popup>
      </div>
    </div>
  </div>
</template>

<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
import departmentService from '@/http/requests/system/departmentService'
import { Notify } from '@robustshell/utils'

export default {
  mixins: [GridBase],
  props: {
  },
  data() {
    return {
      departments: [],
      departmentForm: {
        id: '',
        departmentName: '',
      },
    }
  },
  computed: {
    departmentFormPopup() {
      return this.$refs.departmentFormPopupRef.instance
    },
    departmentFormSheet() {
      return this.$refs.departmentFormRef.instance
    },
    saveButtonOptions() {
      return {
        text: 'Save',
        type: 'success',
        useSubmitBehavior: true,
        onClick: () => {
          this.handleSubmit()
        },
      }
    },
    cancelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.departmentFormPopup.hide()
          this.departmentFormSheet.resetValues()
        },
      }
    },
  },
  async mounted() {
    await this.getDepartments()
  },
  methods: {
    getDepartments() {
      departmentService.getTenantDepartments().then(data => {
        this.departments = data
      })
    },
    openDepartmentForm(e) {
      this.departmentFormPopup.show()
    },
    onClickEdit(e) {
      this.departmentForm.id = e.data?.id
      this.departmentForm.departmentName = e.data?.departmentName
      this.departmentFormPopup.show()
    },
    onClickDelete(id) {
      this.$swal({
        title: 'Are you sure you want to delete this department ?',
        text: 'Please be aware that ALL USER - DEPARTMENT ASSIGNMENTS are also deleted',
        icon: 'error',
        input: 'text',
        inputPlaceholder: 'Type DELETE to confirm (case sensitive)',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
          input: 'my-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value === 'DELETE') {
          try {
            await departmentService.deleteDepartment(id)
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: 'Location record has been deleted.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              this.getDepartments()
            })
          } catch (err) {
            const messeage = err.message || err
            Notify.error(messeage)
          }
        } else {
          Notify.warning('Department is not deleted. Please type DELETE to confirm')
        }
      })
    },
    async handleSubmit() {
      const validationResult = this.departmentFormSheet.validate()
      if (validationResult.isValid) {
        if (this.departmentForm.id) {
          await departmentService.updateDepartment(this.departmentForm)
          this.getDepartments()
        } else {
          await departmentService.createDepartment(this.departmentForm)
          this.getDepartments()
        }
        this.departmentFormPopup.hide()
      }
    },
    resetForm() {
      this.departmentFormSheet.resetValues()
      this.departmentForm.id = ''
    },
  },
}
</script>

<style lang="scss">
</style>
